import axiosInstance from "./axiosInstance";

const getClients = async (include = "sms", page = 1, limit = 10) => {
  const response = await axiosInstance.get("user/clients/all", {
    params: {
      include,
      page,
      limit,
    },
  });
  return response.data;
};

const getClientByUserCode = async (userCode: string) => {
  const response = await axiosInstance.get(
    `user/client/${userCode}?include=sms`
  );
  return response.data;
};

const getAdminList = async () => {
  const response = await axiosInstance.get(`user/admin/all`);
  return response.data;
};

const resetClientPassword = async (data: { email: string }) => {
  const response = await axiosInstance.post(`user/admin/reset-password`, data);
  return response.data;
};
const deasctivateAccount = async (userCode: string) => {
  const response = await axiosInstance.post(`user/desable/account/${userCode}`);
  return response.data;
};
const activateAccount = async (userCode: string) => {
  const response = await axiosInstance.post(`user/enable/account/${userCode}`);
  return response.data;
};

const getRoles = async () => {
  const response = await axiosInstance.get("role/get");
  return response.data;
};

const deleteRole = async (id: number) => {
  const response = await axiosInstance.post(`role/delete/${id}`);
  return response.data;
};

const editRole = async (
  id: number,
  role: { name: string; status: boolean }
) => {
  const response = await axiosInstance.post(`role/update/${id}`, role);
  return response.data;
};

const createRole = async (data: { name: string; status: boolean }) => {
  const response = await axiosInstance.post("role/create", data);
  return response.data;
};

export const adminServices = {
  getRoles,
  deleteRole,
  createRole,
  editRole,
  getClients,
  getClientByUserCode,
  resetClientPassword,
  deasctivateAccount,
  activateAccount,
  getAdminList,
};
