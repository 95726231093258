import React, { useCallback, useEffect, useState } from "react";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import AuthLayout from "../../components/auth/AuthLayout";
import { UilElipsisDoubleVAlt, UilArrowLeft } from "@iconscout/react-unicons";
import toast from "react-hot-toast";
import CreateSession from "../../components/whatsApp/CreateSession";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { whatsappService } from "../../services/whatsappService";
import { formatPhoneNumber } from "./WhatsappPage";
import { logMessage } from "../../utils/logMessage";
import { Socket } from "socket.io-client";
import UserApiKeys from "../../components/UserApiKeys";
import { Link } from "react-router-dom";
import { Session } from "../../components/whatsApp/UserWhatsappSessions";
import GenerateApiKeys from "../../components/GenerateApiKeys";
import { ApiService } from "../../services/apiService";

type ApiKeysPageProps = {
  socket: Socket;
};

const ApiKeysPage: React.FC<ApiKeysPageProps> = ({ socket }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [view, setView] = useState<"main" | "init" | "generate">("main");
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [base64Url, setBase64Url] = useState<string | undefined>();
  const [existingSession, setExistingSession] = useState<boolean>(false);
  const [isScanning, setIsScanning] = useState(false);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [apiKeys, setApiKeys] = useState<any[]>([]);
  const { currentUser } = useSelector((state: RootState) => state.auth);

  const hasActiveSubscription = useCallback(
    () => currentUser?.activeSubscriptions,
    [currentUser]
  );

  const initSession = async (formattedPhoneNumber: string) => {
    setIsLoading(true);
    const userPackageId = currentUser?.activeSubscriptions?.userPackageId;
    if (!userPackageId) {
      return;
    }
    try {
      const response = await whatsappService.initSession(
        formattedPhoneNumber,
        userPackageId
      );
      return response;
    } catch (error) {
      toast.error(
        "An error occurred during session initialization. Please try again later."
      );
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleInitializeSession = useCallback(async () => {
    if (!phoneNumber) {
      toast.error("Please enter a phone number.");
      return;
    }

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    const response = await initSession(formattedPhoneNumber);

    if (response) {
      if (response.status === -1) {
        toast.error(response.message);
      } else if (response.data) {
        setBase64Url(response.data);
        toast.success("Session initialized successfully!");
        setCurrentStep(1);
        setExistingSession(false);
      } else {
        toast.success("You already have an active session!");
        setExistingSession(true);
      }
    }
  }, [phoneNumber]);

  const fetchUserSessions = async () => {
    try {
      const res = await whatsappService.getUserInitSessions();
      if (res.status !== -1) {
        setSessions(res.data);
      }
    } catch (error) {}
  };

  const getUserApiKeys = async () => {
    try {
      const res = await ApiService.getUserApiKeys();
      if (res.status !== -1) {
        setApiKeys(res.data);
      }
    } catch (error) {}
  };

  const handleBackStep = () => {
    setCurrentStep(0);
  };

  useEffect(() => {
    const handleWhatsappStatus = (data: any) => {
      if (data.message === "Session status: qrReadSuccess") {
        setIsScanning(true);
      }
      if (data.message === "Session status: inChat") {
        setIsScanning(false);
        setView("main");
      }
    };
    socket.on("whatsapp_status", handleWhatsappStatus);

    return () => {
      socket.off("whatsapp_status", handleWhatsappStatus);
    };
  }, [socket]);

  useEffect(() => {
    fetchUserSessions();
    getUserApiKeys();
  }, []);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title d-flex align-items-center">
                  {view !== "main" && (
                    <button
                      className="btn btn-light-brand p-1 px-3 me-3"
                      onClick={() => setView("main")}
                    >
                      <UilArrowLeft />
                      Back to menu
                    </button>
                  )}
                  <h5 className="m-b-10 border-0">API Keys</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <button
                    onClick={() => setView("generate")}
                    className="btn btn-primary"
                  >
                    Add New key
                  </button>
                </div>
              </div>
            </div>
            <div className="main-content">
              {!hasActiveSubscription() ? (
                <div className="mx-auto d-flex flex-column justify-content-center align-items-center">
                  <h3>Welcome!</h3>
                  <p className="mb-1 text-black fs-14">
                    It looks like you don't have an active subscription at the
                    moment.
                  </p>
                  <p className="fs-14 mb-0">
                    To start sending messages via WhatsApp API, please choose a
                    subscription plan that best suits your needs.
                  </p>
                  <Link to="/subscribe" className="btn btn-primary mt-3">
                    Subscribe Now
                  </Link>
                </div>
              ) : (
                <>
                  {view === "main" && (
                    <>
                      <div className="row">
                        {sessions.length !== 0 && apiKeys.length === 0 && (
                          <div className="alert alert-info mb-3 col-xxl-8">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <UilElipsisDoubleVAlt />
                                <h5
                                  className=" text-truncate m-0"
                                  data-noteheading={"API Keys"}
                                >
                                  No API keys generated yet
                                </h5>
                              </div>
                              <button
                                onClick={() => setView("generate")}
                                className="btn btn-info"
                              >
                                Generate keys
                              </button>
                            </div>
                          </div>
                        )}

                        {sessions.length === 0 && (
                          <div className="mx-auto d-flex flex-column justify-content-center align-items-center">
                            <h3>Welcome!</h3>
                            <p className="mb-1 text-black fs-14">
                              It looks like you don't have any initialized
                              sender number.
                            </p>
                            <p className="fs-14 mb-0">
                              To start sending messages via WhatsApp, please
                              initialize a sender number from which your
                              contacts will receive your messages.
                            </p>
                            <button
                              className="btn btn-primary mt-3"
                              onClick={() => setView("init")}
                            >
                              Get started
                            </button>
                          </div>
                        )}
                      </div>
                      {apiKeys.length !== 0 && (
                        <div className="row p-0">
                          <UserApiKeys apis={apiKeys} />
                        </div>
                      )}
                    </>
                  )}
                  {view === "init" && (
                    <div className="">
                      {/* <h3></h3> */}
                      <CreateSession
                        currentStep={currentStep}
                        isLoading={isLoading}
                        existingSession={existingSession}
                        isScanning={isScanning}
                        phoneNumber={phoneNumber}
                        base64Url={base64Url}
                        setPhoneNumber={setPhoneNumber}
                        handleInitializeSession={handleInitializeSession}
                        handleBackStep={handleBackStep}
                      />
                    </div>
                  )}
                  {view === "generate" && (
                    <GenerateApiKeys
                      sessions={sessions}
                      getUserApiKeys={getUserApiKeys}
                      setView={setView}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ApiKeysPage;
