import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import {
  UilAngleLeftB,
  UilCheckCircle,
  UilTimesCircle,
  UilAlignRight,
  UilPen,
} from "@iconscout/react-unicons";
import { ApiService } from "../../services/apiService";
import toast from "react-hot-toast";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

type Props = {};

type IpAddress = {
  id: number;
  public_key: string;
  secret_key: string;
  ipAddress: string;
  isActive: boolean;
  isIpWhitelisted: boolean;
  createdAt: string;
  expiresAt: string;
  user: any;
  userWhatsAppNumber: any;
};

const ManageIpAddresses: React.FC = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [ipAddresses, setIpAddresses] = useState<IpAddress[]>([]);
  const [editedIp, setEditedIp] = useState<IpAddress | null>(null);
  const [selectedIp, setSelectedIp] = useState<IpAddress | null>(null);
  const [action, setAction] = useState<"enable" | "disable">("enable");

  const handleIpAddress = async () => {
    if (!selectedIp) return;
    try {
      setLoading(true);
      const res = selectedIp.isIpWhitelisted
        ? await ApiService.disableIp(
            selectedIp.ipAddress,
            selectedIp.userWhatsAppNumber.id
          )
        : await ApiService.enableIp(
            selectedIp.ipAddress,
            selectedIp.userWhatsAppNumber.id
          );

      if (res.status === -1) {
        toast.error(res.message || "An error occurred, please try again later");
        return;
      }
      toast.success(res.message || "Operation successful!");
      fetchIpAddresses();
    } catch (error) {
      toast.error("An error occurred, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const fetchIpAddresses = async () => {
    try {
      setLoading(true);
      const res = await ApiService.getAllApiAddresses();
      if (res.status === -1) {
        toast.error(res.message || "An error occured, please try again later");
        return;
      }
      setIpAddresses(res.data);
    } catch (error) {
      toast.error(
        "An error occurred while fetching IP addresses. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleIpEdit = (e: { target: { value: any } }) => {
    if (editedIp)
      setEditedIp({
        ...editedIp,
        ipAddress: e.target.value,
      });
  };

  const editIpAddress = async () => {
    if (!editedIp || !editedIp.ipAddress) return; // Ensure editedIp and ipAddress are defined
    try {
      setLoading(true);
      const data = {
        userWhatsAppNumberId: editedIp.userWhatsAppNumber.id,
        userId: editedIp.user.id,
        ipAddress: editedIp.ipAddress, // Now guaranteed to be a string
      };
      const res = await ApiService.adminEditIp(data);
      if (res.status === -1) {
        toast.error(res.message || "An error occurred, please try again later");
        return;
      }
      toast.success("Ip updated succesfully");
      fetchIpAddresses();
    } catch (error) {
      toast.error(
        "An error occurred while updating the IP address. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIpAddresses();
  }, []);

  const confirmAction = (
    ipAddress: IpAddress,
    actionType: "enable" | "disable"
  ) => {
    setSelectedIp(ipAddress);
    setAction(actionType);
  };

  const renderIpRow = (ipAddress: IpAddress) => (
    <div key={ipAddress.id} className="hstack gap-3 justify-content-between">
      <div className="hstack gap-3">
        <div
          className={`wd-7 ht-7 rounded-circle ${
            ipAddress.isIpWhitelisted ? "bg-success" : "bg-danger"
          }`}
        ></div>
        <div
          className={`ps-3 border-start border-3 rounded ${
            ipAddress.isIpWhitelisted ? "border-success" : "border-danger"
          }`}
        >
          <div className="d-flex gap-3 justify-content-between align-items-center">
            <span className="fw-semibold mb-1  text-black">
              IP : {ipAddress.ipAddress}
            </span>
            <span className="fw-semibold mb-1 text-black">
              User Code : {ipAddress.user.user_code}
            </span>
            {/* <span className="fw-semibold mb-1 ">
              User userWhatsAppNumber : {ipAddress.user.user_code}
            </span> */}
          </div>
          <span className="fs-12 text-muted d-flex align-items-center">
            {ipAddress.isIpWhitelisted ? (
              <UilCheckCircle className="fs-10 me-1 text-success" />
            ) : (
              <UilTimesCircle className="fs-10 me-1 text-danger" />
            )}
            <span className="fw-normal">
              {ipAddress.isIpWhitelisted ? "Active" : "Inactive"}
            </span>
          </span>
        </div>
      </div>
      <div className="hstack gap-2">
        <button
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          onClick={() =>
            confirmAction(
              ipAddress,
              ipAddress.isIpWhitelisted ? "disable" : "enable"
            )
          }
          className={`btn btn-sm ${
            ipAddress.isIpWhitelisted ? "btn-danger" : "btn-success"
          }`}
          disabled={loading}
        >
          {loading && <span className="loader"></span>}
          {ipAddress.isIpWhitelisted ? "Disable" : "Enable"}
        </button>
        <span
          className="avatar-text avatar-md text-warning"
          title="Delete address"
          onClick={() => setEditedIp(ipAddress)}
          data-bs-toggle="modal"
          data-bs-target="#editModal"
        >
          <UilPen className="text-warning" />
        </span>
      </div>
    </div>
  );

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Manage Ip addresses</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div className="page-header-right-open-toggle">
                    <UilAlignRight />
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="">
                  <div className="card stretch stretch-full">
                    <div className="card-header">
                      <div className="card-header-action">
                        <div className="card-header-btn">
                          <div data-bs-toggle="tooltip" title="Delete">
                            <span
                              className="avatar-text avatar-xs bg-danger"
                              data-bs-toggle="remove"
                            >
                              {" "}
                            </span>
                          </div>
                          <div data-bs-toggle="tooltip" title="Refresh">
                            <span
                              className="avatar-text avatar-xs bg-warning"
                              data-bs-toggle="refresh"
                            >
                              {" "}
                            </span>
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            title="Maximize/Minimize"
                          >
                            <span
                              className="avatar-text avatar-xs bg-success"
                              data-bs-toggle="expand"
                            >
                              {" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body custom-card-action">
                      {loading ? (
                        <div className="p-3">
                          <Stack spacing={1}>
                            <Skeleton variant="rectangular" height={60} />
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                            <Skeleton variant="rectangular" height={60} />
                            <Skeleton variant="rectangular" height={60} />
                          </Stack>
                        </div>
                      ) : (
                        ipAddresses.map((ipAddress) => (
                          <React.Fragment key={ipAddress.id}>
                            {renderIpRow(ipAddress)}
                            <hr className="border-dashed my-3" />
                          </React.Fragment>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* Confirmation Modal */}
        <div
          className="modal fade"
          id="confirmModal"
          tabIndex={-1}
          aria-labelledby="confirmModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="confirmModalLabel">
                  {action === "enable"
                    ? "Enable IP Address"
                    : "Disable IP Address"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Are you sure you want to{" "}
                {action === "enable" ? "enable" : "disable"} this IP address:{" "}
                <strong>{selectedIp?.ipAddress}</strong>?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  data-bs-dismiss="modal"
                  className={`btn btn-${
                    action === "enable" ? "success" : "danger"
                  }`}
                  onClick={handleIpAddress}
                  disabled={loading}
                >
                  {action === "enable" ? "Enable" : "Disable"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* edit ip modal  */}
        <div
          className="modal fade"
          id="editModal"
          tabIndex={-1}
          aria-labelledby="editModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit IP address
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-4">
                  <label className="fw-bold fs-14 text-black mb-1" htmlFor="ip">
                    IP Address
                  </label>
                  <input
                    type="text"
                    name="ip"
                    id="ip"
                    placeholder="IP Address"
                    className="form-control"
                    value={editedIp ? editedIp.ipAddress : ""}
                    onChange={handleIpEdit}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={editIpAddress}
                  disabled={loading}
                  data-bs-dismiss="modal"
                >
                  {loading && <span className="loader"></span>}
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ManageIpAddresses;
