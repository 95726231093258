import React from "react";
import { Link } from "react-router-dom";
import { UilArrowRight } from "@iconscout/react-unicons";

type DashboardCardProps = {
  avatar: React.ReactNode;
  description: string;
  size: string;
  actionName: string;
  redirectUrl: string;
  background: string;
  counter: number | string;
  width: number;
  showProgress?: boolean;
};

const DashboardCard: React.FC<DashboardCardProps> = ({
  avatar,
  description,
  background,
  counter,
  width,
  size,
  actionName,
  redirectUrl,
  showProgress = false,
}) => {
  return (
    <div className={`${size}`}>
      <div className="card stretch stretch-full">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex gap-4 align-items-center mb-3">
              <div className="avatar-text avatar-lg bg-gray-200">{avatar}</div>
              <div className="fs-4 fw-bold text-dark">
                <span className="counter">{counter}</span>
              </div>
            </div>
            <Link to={redirectUrl} className="fs-12 fw-medium text-primary">
              {actionName} <UilArrowRight />
            </Link>
          </div>
          <h3 className="fs-13 fw-semibold text-truncate-1-line mb-3">
            {description}
          </h3>
          <div className="pt-4">
            <div className="d-flex align-items-center justify-content-between">
              <span className="fs-12 fw-medium text-truncate-1-line">
                {description}
              </span>
              {showProgress && (
                <div className="w-100 text-end">
                  <span className="fs-12 text-dark">{counter}</span>
                  <span className="fs-11 text-muted">({width})%</span>
                </div>
              )}
            </div>
            {showProgress && (
              <div className="progress mt-2 ht-3">
                <div
                  className={`progress-bar bg-${background}`}
                  role="progressbar"
                  style={{ width: `${width}% ` }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
