import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  UilAngleRightB,
  UilAngleDown,
  UilSunset,
  UilArrowRight,
  UilAlignLeft,
  UilTimes,
  UilBell,
  UilWallet,
  UilUser,
  UilSignout,
  UilEye,
  UilEyeSlash,
  UilMoon,
} from "@iconscout/react-unicons";
import { useAuth } from "../auth/AuthContext";
import { menuItems } from "../../data/MenuItems";
import { Skeleton } from "@mui/material";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { fetchBalance } from "../../redux/slices/accountSlice";
import {
  subscribePackage,
  getAllPackages,
} from "../../redux/slices/packageSlice";
import { RootState, AppDispatch } from "../../redux/store";

type Props = {};

const CustomNav: React.FC = (props: Props) => {
  const [isMiniMenu, setIsMiniMenu] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState<number | null>(null);
  const location = useLocation();
  const { isAllowed } = useAuth();
  const [showBalance, setShowBalance] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { balance, isLoading } = useSelector(
    (state: RootState) => state.account
  );
  const { packages } = useSelector((state: RootState) => state.packages);

  const hasActiveSubscription = useCallback(
    () => currentUser?.activeSubscriptions,
    [currentUser]
  );

  const firstLetter =
    currentUser && currentUser.firstname ? currentUser.firstname.charAt(0) : "";

  const handleLogout = () => {
    localStorage.removeItem("authToken");
  };

  const handleSubscribe = async () => {
    try {
      setIsSubscribing(true);
      const basicPackage = packages?.find(
        (pkg) => pkg.package_code === "BASIC"
      );

      if (!currentUser || !basicPackage) return;

      const data = {
        packageId: basicPackage?.id,
        userId: currentUser.id,
      };

      const result = await dispatch(
        subscribePackage(data as { userId: number; packageId: number })
      ).unwrap();

      if (result.status === -1) {
        toast.error(
          result.message || "An error occured please try again later"
        );
      } else {
        toast.success(result.message);
        navigate("/whatsapp");
      }
    } catch (error: any) {
      toast.error(`Failed to activate free messages: ${error.message}`);
    } finally {
      setIsSubscribing(false);
    }
  };

  useEffect(() => {
    dispatch(fetchBalance());
    dispatch(getAllPackages());
  }, [dispatch]);

  // Toggle menu resize
  const toggleResize = () => {
    setIsMiniMenu(!isMiniMenu);
  };

  // Toggle visibility for small screens
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const toggleSubMenu = (index: number) => {
    setOpenSubMenuIndex(openSubMenuIndex === index ? null : index);
  };

  const isActive = (path: string) => {
    return location.pathname === `/${path}` || location.pathname === path;
  };

  const isSubMenuActive = (subMenuItems: { path: string }[]) => {
    return subMenuItems.some((subItem) =>
      location.pathname.includes(subItem.path)
    );
  };

  const filteredMenuItems = menuItems.filter((item) => isAllowed(item.path));

  useEffect(() => {
    const menu = document.getElementById("side-bar") as HTMLDivElement;

    if (isVisible && menu) {
      // Handle outside click
      const handleClickOutside = (event: MouseEvent) => {
        if (menu && !menu.contains(event.target as Node)) {
          setIsVisible(false); // Close the sidebar
        }
      };

      // Add event listener for clicks
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        // Cleanup event listener
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isVisible]);

  // Add event listeners after component mounts
  useEffect(() => {
    const menu = document.getElementById("side-bar") as HTMLDivElement;

    if (menu) {
      const handleMouseEnter = () => {
        menu.classList.add("expanded");
      };

      const handleMouseLeave = () => {
        menu.classList.remove("expanded");
      };

      menu.addEventListener("mouseenter", handleMouseEnter);
      menu.addEventListener("mouseleave", handleMouseLeave);

      // Cleanup event listeners when the component unmounts
      return () => {
        menu.removeEventListener("mouseenter", handleMouseEnter);
        menu.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [isMiniMenu]); // Re-run the effect if `isMiniMenu` changes
  return (
    <div className={`custom-nav navigation ${isMiniMenu ? "" : "collapsed"}`}>
      <nav
        id="side-bar"
        className={`side-nav bg-white expanded
             ${isVisible ? "visible" : ""}`}
      >
        <div className="m-header border px-3 py-2  ">
          <Link className="b-brand mx-auto logo-container h-100" to="/">
            <img
              src="../assets/images/nufiLogo.png"
              alt=""
              className={`logo-icon logo log-sm ${
                isMiniMenu ? "visible" : "hidden"
              }`}
              style={{ width: "50px" }}
            />

            <img
              src="../assets/images/dark.png"
              alt=""
              className={`logo-full logo log-lg ${
                isMiniMenu ? "hidden" : "visible"
              }`}
              style={{ height: "70px" }}
            />
          </Link>
        </div>
        <div className="navbar-content border-end">
          <ul className="nxl-navbar list-group list-unstyled">
            <li className="nxl-item nxl-caption fw-bold justify-content-between align-items-center">
              <label className="text-primary fs-10">NAVIGATION</label>

              <div
                className="hamburger text-black cursor-pointer"
                onClick={toggleResize}
              >
                <UilTimes />
              </div>
              <div
                className="toggle d-lg-none text-black cursor-pointer"
                onClick={toggleVisibility}
              >
                <UilTimes />
              </div>
            </li>
            {filteredMenuItems.map((menuItem, index) => (
              <li
                key={index}
                className={`nxl-item nxl-hasmenu ${
                  isActive(menuItem.path) ||
                  isSubMenuActive(menuItem.subMenu || [])
                    ? "active"
                    : ""
                }`}
              >
                {menuItem.subMenu ? (
                  <div
                    className={`nufi-link cursor-pointer ${
                      isActive(menuItem.path) ||
                      isSubMenuActive(menuItem.subMenu || [])
                        ? "active"
                        : ""
                    }`}
                    onClick={() => toggleSubMenu(index)}
                  >
                    <div className="d-flex-align-items-center">
                      <span className="nxl-micon">{menuItem.icon}</span>
                      <span className="nxl-mtext">{menuItem.label}</span>
                    </div>
                    <span className="nxl-arrow float-right">
                      {openSubMenuIndex === index ? (
                        <UilAngleDown />
                      ) : (
                        <UilAngleRightB />
                      )}
                    </span>
                  </div>
                ) : (
                  <Link
                    to={menuItem.path}
                    className={`nufi-link ${
                      isActive(menuItem.path) ? "active" : ""
                    }`}
                  >
                    <div className="d-flex-align-items-center">
                      <span className="nxl-micon">{menuItem.icon}</span>
                      <span className="nxl-mtext">{menuItem.label}</span>
                    </div>
                  </Link>
                )}
                {menuItem.subMenu && openSubMenuIndex === index && (
                  <ul
                    className={`nxl-submenu list-unstyled ${
                      isMiniMenu ? "d-none" : ""
                    } ${openSubMenuIndex === index ? "open" : ""}`}
                  >
                    {menuItem.subMenu.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className={`nxl-item ${
                          isActive(subItem.path) ? "active" : ""
                        }`}
                      >
                        {subItem.path.startsWith("http") ? (
                          <Link
                            className="nufi-link"
                            to={subItem.path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {subItem.label}
                          </Link>
                        ) : (
                          <Link
                            className={`nufi-link ${
                              isActive(subItem.path) ? "active" : ""
                            }`}
                            to={`/${subItem.path}`}
                          >
                            {subItem.label}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <div className="m-4">
            <div className="card d-none text-center">
              <div className="card-body">
                <UilSunset />
                <h6 className="mt-4 text-dark fw-bolder">Stay Connected</h6>
                <p className="fs-11 my-3 text-dark">
                  Flexible integration of professional SMS
                </p>
                <Link to="whatsapp" className="btn btn-primary text-dark w-100">
                  Send Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <header className="head-nav end-0 border bg-white d-flex align-items-center justify-content-between">
        <div
          className="hamburger w-auto h-auto menu-toggle text-black "
          onClick={toggleResize}
        >
          {isMiniMenu ? <UilArrowRight /> : <UilAlignLeft />}
        </div>
        <div
          className="toggle menu-toggle text-black"
          onClick={toggleVisibility}
        >
          display
        </div>
        {!hasActiveSubscription() && !loading && (
          <div className="alert alert-info m-0 bg-transparent border-0 d-flex flex-column flex-md-row align-items-center justify-content-between">
            <p className="m-0 fw-bold">Enjoy 10 free WhatsApp messages!</p>
            <button
              className="btn btn-info ms-2"
              onClick={handleSubscribe}
              disabled={isLoading}
            >
              {isSubscribing && <span className="loader"></span>}
              Try it out now.
            </button>
          </div>
        )}
        <div
          className={`d-flex gap-3 align-items-center ${
            !isMiniMenu && "left-header"
          }`}
        >
          <div className="d-none d-md-flex align-items-center gap-2 justify-content-center">
            <Link
              to={`/settings?item=${"account-balance"}`}
              className="text-muted"
            >
              {balance && Number(balance) === 0 ? (
                <button className="btn btn-sm btn-primary">
                  <UilWallet /> Top Up
                </button>
              ) : (
                "Available Balance :"
              )}
            </Link>
            {isLoading ? (
              <Skeleton
                variant="text"
                style={{ width: "100px" }}
                sx={{ fontSize: "1rem" }}
              />
            ) : (
              <h5 className="m-0 text-primary">
                {showBalance
                  ? `$${Number(balance)?.toFixed(2) || "0.00"}`
                  : "******"}
              </h5>
            )}
            <span
              className="cursor-pointer text-primary"
              onClick={() => setShowBalance(!showBalance)}
            >
              {showBalance ? <UilEyeSlash /> : <UilEye />}
            </span>
          </div>
          <div className="avatar-image avatar-sm">
            <img
              src="https://getnexel.netlify.app/assets/vendors/img/flags/1x1/us.svg"
              alt=""
              className="img-fluid"
            />
          </div>
          <UilMoon />
          <UilBell />
          <div className="dropdown nxl-h-item pe-2">
            <button
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              className="bg-transparent border-0"
            >
              <div
                style={{ height: "35px", width: "35px" }}
                className="border border-gray-2 rounded-circle bg-nf-secondary d-flex justify-content-center align-items-center"
              >
                <h5 className="text-center text-white my-auto">
                  {firstLetter}
                </h5>
              </div>
            </button>
            <div className="dropdown-menu dropdown-menu-end nxl-h-dropdown nxl-user-dropdown">
              <div className="dropdown-header">
                <div className="d-flex align-items-center">
                  <div>
                    <h6 className="text-dark mb-0">
                      {currentUser?.firstname} {currentUser?.lastname}
                    </h6>
                    <span className="fs-12 fw-medium text-muted">
                      {currentUser?.email}
                    </span>
                  </div>
                </div>
              </div>

              <Link
                to={`/settings?item=${"profile-details"}`}
                className="dropdown-item"
              >
                <UilUser />
                <span>Profile Details</span>
              </Link>
              <Link
                to={`/settings?item=${"account-balance"}`}
                className="dropdown-item"
              >
                <UilWallet />
                <span>Account Balance</span>
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/auth" onClick={handleLogout}>
                <UilSignout />
                <span>Logout</span>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default CustomNav;
