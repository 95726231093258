import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { whatsappService } from "../../services/whatsappService";
import { SMS } from "../../models/SMS";

interface whatsappStatae {
  messages: SMS[]; // Add this to store recharges
  errors: string | null;
  isLoading: boolean;
}

const initialState: whatsappStatae = {
  messages: [],
  errors: null,
  isLoading: false,
};

// Async thunks
export const fetchMessages = createAsyncThunk(
  "whatsapp/fetchMessages",
  async ({
    page,
    limit,
    filters,
  }: {
    page: number;
    limit: number;
    filters: any;
  }) => {
    const response = await whatsappService.getMessageHistory({
      page,
      limit,
      filters,
    });
    return response.data;
  }
);

const whatsappSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Balance
      .addCase(fetchMessages.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.messages = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.errors = action.error.message || "Failed to fetch history";
        state.isLoading = false;
      });
  },
});

export default whatsappSlice.reducer;
