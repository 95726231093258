import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { Client } from "../models/Client";
import { Country, Operator } from "../models/Coverage";

export interface RegisterValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: { label: string; value: string } | string;
  address: string;
  company: string;
}

export const initialValues: RegisterValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  country: "",
  address: "",
  company: "",
};

// Define the validation schema for registration
export const registerValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .required("Phone is required")
    .test("is-valid-phone", "Invalid phone number", function (value) {
      return value ? isValidPhoneNumber(value) : false;
    }),
  country: Yup.mixed()
    .required("Country is required")
    .test("is-valid-country", "Country is required", function (value: any) {
      // Explicitly type `value` as `any` since it could be a string or an object
      return (
        typeof value === "string" ||
        (value &&
          typeof value.label === "string" &&
          typeof value.value === "string")
      );
    }),
  address: Yup.string().required("Address is required"),
});

export const createClientValues: Partial<Client> = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  country: "",
  address: "",
  company: "",
  status: "",
  // password: "",
  // confPassword: "",
  // state: "",
  // city: "",
  // login: "",
};

// Define the validation schema for creating a client
export const createClientValidationSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name is required"),
  lastname: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .required("Phone is required")
    .test("is-valid-phone", "Invalid phone number", function (value) {
      return value ? isValidPhoneNumber(value) : false;
    }),
  country: Yup.string().required("Country is required"),
  address: Yup.string().required("Address is required"),
  // login: Yup.string().required("Login is required"),
  // company: Yup.string().required("Company is required"),
  // status: Yup.string().required("Status is required"),
  // password: Yup.string().required("Password is required"),
  // confPassword: Yup.string()
  //   .required("Confirm Password is required")
  //   .oneOf([Yup.ref("password")], "Passwords must match"),
  // state: Yup.string().required("State is required"),
  // city: Yup.string().required("City is required"),
});

// coverage part
export const pricingInitialValues = {
  operatorName: "",
  countryId: "",
  pricingPartner: 0,
  pricingReseller: 0,
  percentageReseller: 0,
};

export const pricingValidationSchema = Yup.object().shape({
  operatorName: Yup.string().required("Required"),
  countryId: Yup.string().required("Required"),
  pricingPartner: Yup.number()
    .required("Required")
    .positive("Must be positive"),
  // pricingReseller: Yup.number()
  //   .required("Required")
  //   .positive("Must be positive"),
  percentageReseller: Yup.number()
    .required("Required")
    .min(0, "Must be at least 0")
    .max(100, "Must be at most 100"),
});

export const countryInitialValues: Partial<Country> = {
  name: "",
  iso2: "",
  iso3: "",
  currency: "",
  phone_code: "",
};
export const countryValidationSchema = Yup.object().shape({
  phone_code: Yup.string().required("Phone Code is required"),
  name: Yup.string().required("Country Name is required"),
  iso2: Yup.string().required("ISO2 is required"),
  iso3: Yup.string().required("ISO3 is required"),
  currency: Yup.string().required("Currency is required"),
});

export const operatorInitialValues: Operator = {
  operatorName: "",
  codeOperator: "",
};
export const operatorValidationSchema = Yup.object().shape({
  operatorName: Yup.string().required("Operator Name is required"),
});
