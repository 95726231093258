import React, { useEffect, useState } from "react";
import {
  UilLock,
  UilPlus,
  UilAngleLeftB,
  UilAngleRightB,
  UilCheckCircle,
  UilMoneyInsert,
  UilUserTimes,
} from "@iconscout/react-unicons";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import Profile from "../../components/clientDetails/Profile";
import { Client } from "../../models/Client";
import { adminServices } from "../../services/adminService";

type Props = {};

const ClientDetails = (props: Props) => {
  const [isloading, setIsloading] = React.useState(false);
  const [isActive, setActive] = useState(true);
  const [clientData, setClientData] = React.useState<Client>();
  const [clientEmail, setClientEmail] = useState<string | null>(null);
  const navigate = useNavigate();
  const { userCode } = useParams();

  const firstLetter =
    clientData && clientData.firstname ? clientData.firstname.charAt(0) : "";

  const handleResetPassword = async () => {
    try {
      if (clientData && clientData.email) {
        setIsloading(true);
        await adminServices
          .resetClientPassword({ email: clientData.email })
          .then((res: any) => {
            if (res.status === -1) {
              toast.error(res.message);
              return;
            }
            toast.success(res.message);
            setClientEmail(null);
          })
          .catch((err: any) => {
            toast.error(
              "Can not reset client password. Please try again later"
            );
          })
          .finally(() => {
            setIsloading(false);
          });
      }
    } catch (error) {
      toast.error(
        "An error occured while fetching clients. Please try again later."
      );
    }
  };
  const handleActivation = async () => {
    try {
      if (clientData && clientData.user_code) {
        setIsloading(true);
        const action = isActive
          ? adminServices.deasctivateAccount(clientData.user_code)
          : adminServices.activateAccount(clientData.user_code);

        await action
          .then((res: any) => {
            if (res.status === -1) {
              toast.error(res.message);
              return;
            }
            toast.success(res.message);

            // Update the isActive state manually to reflect the change immediately
            setActive(!isActive);
          })
          .catch((err: any) => {
            toast.error("Can not perform operation. Please try again later");
          })
          .finally(() => {
            setIsloading(false);
          });
      }
    } catch (error) {
      toast.error(
        "An error occurred while fetching clients. Please try again later."
      );
    }
  };

  const handleUpdateBalance = () => {
    navigate(`/client-details/${clientData?.user_code}/balance`);
  };

  const fetchClient = async () => {
    try {
      if (userCode) {
        setIsloading(true);
        await adminServices
          .getClientByUserCode(userCode)
          .then((res: any) => {
            if (res.status === -1) {
              return;
            }
            setClientData(res.data);
            if (res.data.status === "Account_desactivated") {
              setActive(false);
            }
          })
          .catch((err: any) => {})
          .finally(() => {
            setIsloading(false);
          });
      }
    } catch (error) {
      toast.error(
        "An error occured while fetching clients. Please try again later."
      );
    }
  };

  useEffect(() => {
    fetchClient();
  }, []);
  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Client Details</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <a className="btn btn-primary" href="/client-create">
                      <UilPlus />
                      <span>Create Client</span>
                    </a>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div className="page-header-right-open-toggle">
                    <UilAngleRightB />
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-xxl-4 col-xl-6  col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-4 text-center">
                        <div className="wd-100 ht-100 mx-auto border border-gray-2 rounded-circle bg-primary d-flex justify-content-center align-items-center">
                          <h5 className="text-center text-white ">
                            {firstLetter}
                          </h5>
                        </div>
                        <div className="mb-4">
                          <div className="fs-14 fw-bold d-block">
                            {clientData?.firstname} {clientData?.lastname}
                          </div>
                          <div className="fs-12 fw-normal text-muted d-block">
                            {clientData?.email}
                          </div>
                        </div>
                        <div className="fs-12 fw-normal text-muted text-center d-flex flex-wrap gap-3 mb-4">
                          <div className="flex-fill py-3 px-4 rounded-1 d-none d-sm-block border border-dashed border-gray-5">
                            <h6 className="fs-15 fw-bolder">
                              {clientData?.sentMessages
                                ? `${clientData.sentMessages.length}`
                                : "0"}
                            </h6>
                            <p className="fs-12 text-muted mb-0">
                              Total SMS Sent
                            </p>
                          </div>
                          <div className="flex-fill py-3 px-4 rounded-1 d-none d-sm-block border border-dashed border-gray-5">
                            <h6 className="fs-15 fw-bolder">$2000</h6>
                            <p className="fs-12 text-muted mb-0">SMS Balance</p>
                          </div>
                          <div className="flex-fill py-3 px-4 rounded-1 d-none d-sm-block border border-dashed border-gray-5">
                            <button
                              onClick={handleUpdateBalance}
                              className="btn btn-primary btn-light-brand mb-1 p-0 py-2 w-100"
                            >
                              <UilMoneyInsert />
                              Update Balance
                            </button>
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#passwordModal"
                              className="btn btn-primary btn-light-brand p-0 py-1 w-100 mb-1"
                            >
                              <UilLock />
                              <span className="">Reset Password</span>
                            </button>
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#activationModal"
                              className={`btn btn-${
                                isActive ? "danger" : "success"
                              } btn-light-brand p-0 py-1 w-100`}
                            >
                              {isActive ? <UilUserTimes /> : <UilCheckCircle />}
                              <span className="">
                                {isActive
                                  ? "Deactivate Account"
                                  : "Activate Account"}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Invoices</h5>
                    </div>
                    <div className="card-body"></div>
                    <div
                      className="ladda-button zoom-out"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      title="Refresh Now"
                    >
                      <span>Refresh</span>
                      <span className="spinner"></span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-8 col-xl-6 col-md-8">
                  <div className="card border-top-0">
                    <div className="card-header p-0">
                      {/* <!-- Nav tabs --> */}
                      <ul
                        className="nav nav-tabs flex-wrap w-100 text-center customers-nav-tabs"
                        id="myTab"
                        role="tablist"
                      >
                        <li
                          className="nav-item flex-fill border-top"
                          role="presentation"
                        >
                          <div
                            className="nav-link active cursor-pointer"
                            data-bs-toggle="tab"
                            data-bs-target="#overviewTab"
                            role="tab"
                          >
                            Client Profile
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      <Profile />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div
          className="modal fade"
          id="passwordModal"
          tabIndex={-1}
          aria-labelledby="passwordModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex flex-column">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Reset Client Password
                  </h5>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p className="text-secondary">
                  The client's new credentials will be sent to their email
                  address
                </p>
                {/* <form className="w-100">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <label htmlFor="oldPassword">Client Email</label>
                      <div className={`d-flex`}>
                        <input
                          type="email"
                          className="form-control border-end-0 rounded-start rounded-0"
                          placeholder="Client Email"
                          required
                          id="email"
                          value={clientEmail ?? ""}
                          name="email"
                          onChange={(e) => setClientEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </form> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleResetPassword}
                  disabled={isloading || !clientData}
                  data-bs-dismiss="modal"
                >
                  {isloading && <span className="loader"></span>}
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* activationModal  */}
        <div
          className="modal fade"
          id="activationModal"
          tabIndex={-1}
          aria-labelledby="activationModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {isActive ? "Deactivate Account" : "Activate Account"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {" "}
                Do you really want to {isActive ? "deactivate" : "activate"} the
                account of user{" "}
                <span className="fw-bold">
                  {clientData?.firstname} {clientData?.lastname}
                </span>{" "}
                ?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleActivation}
                  disabled={isloading}
                  data-bs-dismiss="modal"
                >
                  {isloading && <span className="loader"></span>}
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ClientDetails;
