export type Role = "USER" | "reporter" | "admin" | "SUPER_ADMIN";

interface RolePermissions {
  allowedPaths: string[];
}

export const rolesPermissions: Record<Role, RolePermissions> = {
  USER: {
    allowedPaths: [
      "/",
      "/dashboard",
      "/direct-sms",
      "/send-bulk",
      "/send-bulk-group",
      "/whatsapp-history",
      "/sms-historic",
      "/settings",
      "/contacts",
      "/contacts-create",
      "/contacts-group",
      "/all-pricing",
      "/subscribe",
      "/whatsapp",
      "/apis",
      "/developers",
    ],
  },
  reporter: {
    allowedPaths: [
      "/",
      "/dashboard",
      "/direct-sms",
      "/send-bulk",
      "/send-bulk-group",
      "/sms-historic",
      "/settings",
      "/contacts",
      "/contacts-create",
      "/contacts-group",
      "/payment",
      "/payment-list",
      "/clients",
      "/client-list",
      "/client-create",
      "/coverage",
      "/subscribe",
      "/whatsapp",
    ],
  },
  admin: {
    allowedPaths: [
      "/",
      "/dashboard",
      "/direct-sms",
      "/send-bulk",
      "/send-bulk-group",
      "/sms-historic",
      "/settings",
      "/contacts",
      "/contacts-create",
      "/contacts-group",
      "/payment",
      "/payment-list",
      "/clients",
      "/client-list",
      "/client-create",
      "/coverage",
      "/admin",
      "/manage-roles",
      "/packages-whatsapp",
      "/manage-pricing",
      "/subscribe",
      "/whatsapp",
      "/apis",
      "/developers",
      "/manage-ip-addresses",
    ],
  },
  SUPER_ADMIN: {
    allowedPaths: [
      "/",
      "/clients",
      "/client-list",
      "/client-create",
      "/coverage",
      "/admin",
      "/manage-roles",
      "/packages-whatsapp",
      "/manage-pricing",
      "/manage-ip-addresses",
      "/settings",
    ], // Superadmin can access all paths
  },
};
