import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import CustomNav from "../../components/navigation/CustomNav";

type Props = {};

const Menus = [
  { title: "Overview", src: "Overview" },
  { title: "Transactions", src: "Transactions" },
  { title: "Loyalty Cards", src: "Card", gap: true },
  { title: "Subscriptions", src: "Calendar" },
  { title: "Debts", src: "Debt" },
  { title: "Legal information", src: "Legal" },
  { title: "Notifications", src: "Notifications", gap: true },
  { title: "Setting", src: "Settings" },
];

const TestPage = (props: Props) => {
  const [isMiniMenu, setIsMiniMenu] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  // Toggle menu resize
  const toggleResize = () => {
    setIsMiniMenu(!isMiniMenu);
  };

  // Toggle visibility for small screens
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    const menu = document.getElementById("side-bar") as HTMLDivElement;

    if (isVisible && menu) {
      // Handle outside click
      const handleClickOutside = (event: MouseEvent) => {
        if (menu && !menu.contains(event.target as Node)) {
          setIsVisible(false); // Close the sidebar
        }
      };

      // Add event listener for clicks
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        // Cleanup event listener
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isVisible]);

  // Add event listeners after component mounts
  useEffect(() => {
    const menu = document.getElementById("side-bar") as HTMLDivElement;

    if (menu) {
      const handleMouseEnter = () => {
        menu.classList.add("expanded");
      };

      const handleMouseLeave = () => {
        menu.classList.remove("expanded");
      };

      menu.addEventListener("mouseenter", handleMouseEnter);
      menu.addEventListener("mouseleave", handleMouseLeave);

      // Cleanup event listeners when the component unmounts
      return () => {
        menu.removeEventListener("mouseenter", handleMouseEnter);
        menu.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [isMiniMenu]); // Re-run the effect if `isMiniMenu` changes

  return (
    <AuthLayout>
      <CustomNav />
      <div>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="main-content"></div>
          </div>
        </main>
      </div>
    </AuthLayout>
  );
};

export default TestPage;
