// utils/socketEvents.ts
export const SOCKET_EVENTS = {
  RECHARGE_SUCCESS: "recharge_success",
  PAYMENT_INITIALIZED: "payment_initialized",
  WHATSAPP_CONNECTED: "whatsapp_connected",
  PAYMENT_SUCCEEDED: "payment_succeeded",
  WHATSAPP_RESUMING: "whatsapp_resuming",
  WHATSAPP_SYNCING: "whatsapp_syncing",
  WHATSAPP_STATUS: "whatsapp_status",
  WHATSAPP_STATUS_INCHAT: "whatsapp_status_inchat",
  WHATSAPP_ERROR: "whatsapp_error",
  WHATSAPP_DISCONNECTED: "whatsapp_disconnected",
  WHATSAPP_DISCONNECTED_MOBILE: "whatsapp_disconnected_mobile",
  WHATSAPP_IN_CHAT: "whatsapp_in_chat",
  WHATSAPP_PHONE_NOT_CONNECTED: "whatsapp_phone_not_connected",
  WHATSAPP_QR_READ_FAIL: "whatsapp_qr_read_fail",
  WHATSAPP_QR_READ_SUCCESS: "whatsapp_qr_read_success",
  WHATSAPP_BROWSER_CLOSE: "whatsapp_browser_close",
  INSUFFICIENT_BALANCE: "insufficient_balance",
};
