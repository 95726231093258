import React, { useState } from "react";
import { Contact } from "../../models/Contacts";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

type ContactsTableProps = {
  data?: Contact[];
  loading?: boolean;
};

const ContactsTable: React.FC<ContactsTableProps> = ({
  data,
  loading = false,
}) => {
  const [page, setPage] = useState(1);

  const rowsPerPage = 10;
  // Calculate the number of pages for filtered contacts
  const totalPages = data ? Math.ceil(data.length / rowsPerPage) : 0;

  // Slice contacts for the current page
  const currentContacts = data?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const handleChangePage = (event: any, value: number) => {
    setPage(value);
  };

  return (
    <div>
      {loading ? (
        <div className="p-3">
          <Stack spacing={1}>
            <Skeleton variant="rectangular" height={60} />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" height={60} />
            <Skeleton variant="rectangular" height={60} />
          </Stack>
        </div>
      ) : (
        <div className="table-responsive">
          <Stack
            spacing={2}
            sx={{ marginTop: 2, marginBottom: 2 }}
            className="d-flex"
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Stack>
          <table className="table table-hover" id="customerList">
            <thead>
              <tr>
                <th className="wd-30">
                  <div className="btn-group mb-1">
                    <div className="custom-control custom-checkbox ms-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="checkAllCustomer"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkAllCustomer"
                      ></label>
                    </div>
                  </div>
                </th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {currentContacts &&
                currentContacts.map((contact, index) => (
                  <tr key={index} className="single-item">
                    <td>
                      <div className="item-checkbox ms-1">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input checkbox"
                            id="checkBox_1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="checkBox_1"
                          ></label>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a className="hstack gap-3" href="customers-view.html">
                        <div>
                          <span className="text-truncate-1-line">
                            {contact.firstname || "---"}
                          </span>
                        </div>
                      </a>
                    </td>
                    <td>
                      <a className="hstack gap-3" href="customers-view.html">
                        <div>
                          <span className="text-truncate-1-line">
                            {contact.lastname || "---"}
                          </span>
                        </div>
                      </a>
                    </td>
                    <td>
                      <a href="send-single">{contact.email || "---"}</a>
                    </td>
                    <td>
                      <a href="tel:">{contact.phone}</a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {data?.length === 0 && (
            <h5 className="text-center my-5">
              Your contact list will appear here
            </h5>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactsTable;
