import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import {
  UilAngleDoubleRight,
  UilFileCheckAlt,
  UilFileUploadAlt,
} from "@iconscout/react-unicons";
import {
  parseCSVFile,
  parseExcelFile,
  parseTextFile,
} from "../../utils/fileParsers";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import { useDispatch } from "react-redux";
import {
  addContacts,
  addContactsToGroup,
} from "../../redux/slices/contactSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { Contact, ContactGroup } from "../../models/Contacts";
import Select from "react-select";
import { getAllContactGroups } from "../../redux/slices/contactGroupSlice";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { logMessage } from "../../utils/logMessage";
import CreateContactForm from "../../components/contacts/CreateContactForm";

type Props = {};

const ContactsCreate = (props: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [contacts, setContacts] = React.useState<Contact[]>([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isUploaded, setIsUploaded] = React.useState(false);
  const [isloading, setIsloading] = React.useState(false);
  const [groups, setGroups] = React.useState<ContactGroup[] | null>(null);
  const [selectedGroup, setSelectedGroup] = React.useState<{
    value: string;
    label: string;
  } | null>(null);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const dispatch = useDispatch();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsUploading(true);
    setUploadProgress(0);

    let data: Contact[] = [];

    if (file.type === "text/csv") {
      data = await parseCSVFile(file);
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
    ) {
      data = await parseExcelFile(file);
    } else if (file.type === "text/plain") {
      data = await parseTextFile(file);
    }
    setContacts(data);
    setIsUploaded(true);
    setIsUploading(false);
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleGroupChange = (selectedOption: any) => {
    setSelectedGroup(selectedOption);
  };

  const handleDiscard = () => {
    setContacts([]);
    setIsUploaded(false);
  };

  const handleAddNewContact = () => {
    setContacts((prev) => [...prev, formik.values]);
    formik.resetForm();
    formik.validateForm();
  };

  const handleDeleteContact = (index: number) => {
    setContacts((prev) => prev.filter((_, i) => i !== index));
  };

  const handleEditContact = (index: number) => {
    const contact = contacts[index];
    formik.setValues({
      firstname: contact.firstname || "", // Default to empty string if undefined
      lastname: contact.lastname || "",
      phone: contact.phone,
      email: contact.email || "",
    });
    setContacts((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = (contacts: Contact[]) => {
    setIsloading(true);

    try {
      const action = selectedGroup
        ? addContactsToGroup({ contacts, groupCode: selectedGroup.value })
        : addContacts(contacts);

      dispatch(action as unknown as AnyAction)
        .unwrap()
        .then((res: { message: string; status: number }) => {
          toast.success(
            "Contacts Saved Successfully! You can preview them in your contacts page",
            { duration: 6000 }
          );
          handleDiscard();
        })
        .catch((error: any) => {
          toast.error("Failed to save contacts", error);
        })
        .finally(() => {
          setIsloading(false);
        });
    } catch (err: any) {
      const error = err.response.data.message;
      toast.error(error);
    } finally {
      setIsloading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email"),
    }),

    onSubmit: (values) => {},
  });

  const dot = () => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: "#ff488b",
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const customStyles = {
    option: (defaultStyles: any, state: { isSelected: any }) => ({
      // You can log the defaultStyles and state for inspection
      // You don't need to spread the defaultStyles
      ...defaultStyles,
      color: state.isSelected ? "#ff488b" : "#283c50",
      backgroundColor: "transparent",
    }),

    control: (defaultStyles: any) => ({
      ...defaultStyles,
      border: "1px solid #a0a0a0",
      boxShadow: "none",
    }),
    input: (styles: any) => ({ ...styles, ...dot() }),
    placeholder: (styles: any) => ({ ...styles, ...dot() }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      ...dot(),
      color: "#283c50",
    }),
  };

  const transformGroupsToOptions = (groups: ContactGroup[]): any[] => {
    return groups.map((group) => ({
      value: group.group_code,
      label: group.group_name,
    }));
  };

  const transformedGroups = transformGroupsToOptions(groups ?? []);

  useEffect(() => {
    formik.validateForm();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        dispatch(getAllContactGroups() as unknown as AnyAction)
          .unwrap()
          .then((res: ContactGroup[] | any) => {
            if (res.length === 0) {
              setGroups([]);
            } else if (res.status === -1) {
              setGroups([]);
            } else {
              setGroups(res);
            }
          })
          .catch((err: any) => {
            logMessage(err);
          });
      } catch (err) {
        console.error("Failed to fetch contact groups: ", err);
      }
    };

    fetchGroups();
  }, [dispatch]);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card border-top-0">
                    <div className="card-header p-0"></div>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="profileTab"
                        role="tabpanel"
                      >
                        <div className="card-body personal-info">
                          <div className="row">
                            <div className="mb-4 col-12 d-flex align-items-center justify-content-between">
                              <h5 className="fw-bold mb-0 me-4">
                                <span className="d-block mb-2">
                                  Manually enter the personal information OR
                                  upload a file containing them
                                </span>
                                <span className="fs-12 fw-normal text-muted text-truncate-1-line">
                                  Please fill in the information of your contact
                                </span>
                              </h5>
                            </div>
                            <div className="mb-4 d-flex align-items-center justify-content-between">
                              <div className="p-0 col-md-4">
                                {contacts.length !== 0 && (
                                  <>
                                    <label htmlFor="group">
                                      Assign a Group
                                    </label>
                                    <Select
                                      options={transformedGroups}
                                      value={selectedGroup}
                                      onChange={handleGroupChange}
                                      className="form-control border-0 text-dark m-0"
                                      placeholder="Select Group"
                                      styles={customStyles}
                                      id="group"
                                    />
                                  </>
                                )}
                              </div>
                              <div className="d-flex align-items-center justify-content-end">
                                <button
                                  className="btn btn-primary btn-light-brand"
                                  onClick={() => {
                                    handleSave(contacts);
                                  }}
                                  disabled={contacts.length === 0 || isloading}
                                >
                                  {isloading && (
                                    <span className="loader"></span>
                                  )}
                                  Save All
                                </button>
                                <button
                                  onClick={handleDiscard}
                                  className="btn btn-light-brand ms-2"
                                >
                                  Discard
                                </button>
                              </div>
                            </div>
                            <CreateContactForm formik={formik} />
                            <button
                              onClick={handleAddNewContact}
                              disabled={!formik.isValid}
                              className="col-lg-1 my-auto text-white text-center bg-primary avatar-text avatar-lg fs-16"
                              title="Add to List"
                            >
                              <UilAngleDoubleRight />
                            </button>
                            <div className="col-lg-6">
                              {/* <h5 className="card-title">
                                Added Contacts Overview
                              </h5> */}
                              <div className=" card stretch-full">
                                <div className="card-body p-0">
                                  <div className="table-responsive">
                                    <table className="align-middle mb-0 table">
                                      <thead>
                                        <tr>
                                          <th>Firstname</th>
                                          <th>Lastname</th>
                                          <th>Email</th>
                                          <th>Phone</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {contacts.map((contact, index) => (
                                          <tr key={index}>
                                            <td>
                                              {contact.firstname || "---"}
                                            </td>
                                            <td>{contact.lastname || "---"}</td>
                                            <td>{contact.email || "---"}</td>
                                            <td>{contact.phone}</td>
                                            <td className="d-flex gap-3">
                                              <button
                                                className="btn btn-sm btn-warning"
                                                onClick={() =>
                                                  handleEditContact(index)
                                                }
                                              >
                                                Edit
                                              </button>
                                              <button
                                                className="btn btn-sm btn-danger"
                                                onClick={() =>
                                                  handleDeleteContact(index)
                                                }
                                              >
                                                Delete
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-0" />
                        <div className="card-body additional-info">
                          <div className="mb-4 d-flex align-items-center justify-content-end">
                            <button className="btn btn-sm btn-light-brand">
                              Download sample data
                            </button>
                          </div>
                          <div className="d-flex flex-column justify-content-center h-100">
                            {isUploading ? (
                              <>
                                <progress
                                  value={uploadProgress}
                                  max="100"
                                  style={{ width: "100%" }}
                                />
                                <span className="mt-3 text-tertiary">
                                  Uploading and processing...
                                </span>
                              </>
                            ) : isUploaded ? (
                              <div className="my-auto d-flex flex-column align-items-center">
                                <UilFileCheckAlt className="text-success text-center icon-large" />
                                <h4 className="text-success text-center">
                                  Contacts Uploaded Successfully
                                </h4>
                              </div>
                            ) : (
                              <div className="d-flex flex-column align-items-center py-5 cursor-pointer zip-container mb-4 col-md-8 mx-auto">
                                <UilFileUploadAlt className="text-tertiary text-center icon-large" />
                                {/* <img
                                      src="../assets/images/file-icons/zip.png"
                                      alt=""
                                      style={{ height: "80px" }}
                                    /> */}
                                {/* <span className="mt-3 text-tertiary">
                                      Drag and drop files
                                    </span>
                                    <span className="text-tertiary">OR</span> */}
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  onChange={handleFileChange}
                                  accept=".csv, .xlsx, .xls, .txt"
                                  style={{ display: "none" }}
                                />
                                <button
                                  className="btn btn-tertiary rounded mt-2"
                                  onClick={handleButtonClick}
                                >
                                  Browse files
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ContactsCreate;
