import React, { useState } from "react";
import { UilImagePlus } from "@iconscout/react-unicons";

type ImageUploadProps = {
  onFileChange: (file: File | null) => void;
  maxSize?: number;
};

const ImageUpload: React.FC<ImageUploadProps> = ({
  onFileChange,
  maxSize = 2 * 1024 * 1024,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      // Validate file size
      if (file.size > maxSize) {
        setError("File size exceeds 2MB limit");
        setSelectedImage(null);
        onFileChange(null);
        return;
      }

      setError(null);
      onFileChange(file);

      // Preview the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    document.getElementById("fileInput")?.click();
  };

  return (
    <div className="card border-0 file-upload bg-gray-100 p-2">
      {selectedImage ? (
        <div className="image-preview w-100">
          <img src={selectedImage} alt="Selected" className="img-fluid w-100" />
          <button
            className="btn btn-danger btn-sm mt-2"
            onClick={handleRemoveImage}
          >
            Change Image
          </button>
        </div>
      ) : (
        <div
          className="rounded-circle border-2 ht-200 wt-200 mx-auto d-flex justify-content-center align-items-center flex-column cursor-pointer"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          title="Pick an Image"
          onClick={() => document.getElementById("fileInput")?.click()}
        >
          <UilImagePlus size="50" className="text-primary title-icons" />
          <h5>Add an Image</h5>
        </div>
      )}

      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        style={{ display: "none" }}
      />

      {error && <div className="text-danger mt-2">{error}</div>}
    </div>
  );
};

export default ImageUpload;
