import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthState, getCurrentUser } from "../../redux/slices/authSlice";
import { UilExclamationTriangle } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../services/axiosInstance";
import { AppDispatch } from "../../redux/store";

type AuthLayoutProps = {
  children: React.ReactNode;
  redirect?: boolean;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  redirect = true,
}) => {
  const [showTokenExpiryModal, setShowTokenExpiryModal] =
    useState<boolean>(false);
  const [showDeactivatedAccountModal, setShowDeactivatedAccountModal] =
    useState<boolean>(false);
  const currentUser = useSelector<unknown, AuthState>(
    (state: any) => state.auth.currentUser
  );
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = useMemo(() => localStorage.getItem("authToken"), []);

  useEffect(() => {
    if (redirect && !authToken) navigate("/auth");
    const axiosInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          setShowTokenExpiryModal(true);
          localStorage.removeItem("authToken");
        }
        if (error.response && error.response.status === 403) {
          setShowDeactivatedAccountModal(true);
          localStorage.removeItem("authToken");
        }
        return Promise.reject(error);
      }
    );
    if (!currentUser && authToken) {
      dispatch(getCurrentUser());
    }

    return () => {
      axiosInstance.interceptors.response.eject(axiosInterceptor);
    };
  }, [authToken, currentUser, dispatch, navigate, redirect]);

  const handleLoginRedirect = () => {
    setShowTokenExpiryModal(false);
    navigate("/auth");
  };

  return (
    <>
      {showTokenExpiryModal && (
        <div
          className="modal show d-block fade"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex gap-1 align-items-center">
                  <UilExclamationTriangle className="text-warning" />
                  <h5 className="modal-title">Session Expired</h5>
                </div>
              </div>
              <div className="modal-body">
                Your session has expired. Please log in again.
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary m-0"
                  onClick={handleLoginRedirect}
                >
                  Go to Login
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDeactivatedAccountModal && (
        <div
          className="modal show d-block fade"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex gap-1 align-items-center">
                  <UilExclamationTriangle className="text-warning" />
                  <h5 className="modal-title">Account Deactivated</h5>
                </div>
              </div>
              <div className="modal-body">
                Your account has been deactivated. Please contact the
                administrator for further assistance.
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary m-0">
                  Contact Administrator
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export default AuthLayout;
